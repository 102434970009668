<template lang="html">
    <div
        class="ps-page__header bg--cover"
        :style="{
            backgroundImage: `url(/img/hero/blog-grid.jpg)`
        }"
    >
        <div class="container">
            <h3 class="text-uppercase">{{ componentTitle }}</h3>
        </div>
    </div>
</template>

<script>
import { baseUrl } from '@/repositories/Repository';

export default {
    name: 'BlogHero',
    props: {
        componentTitle: {
            type: String,
            default: () => 'Life Style'
        }
    },
    computed: {
        baseURL() {
            return baseUrl;
        }
    }
};
</script>
